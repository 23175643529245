import firebase from 'firebase/app';
import 'firebase/messaging';

var firebaseConfig = {
  apiKey: "AIzaSyCtDqlcexZ2ziPrkQIBZFqxj6gZrSde22M",
  authDomain: "allgreen-dclxvi.firebaseapp.com",
  projectId: "allgreen-dclxvi",
  storageBucket: "allgreen-dclxvi.appspot.com",
  messagingSenderId: "213255223651",
  appId: "1:213255223651:web:3fd3ed806678693d73bb56",
  measurementId: "G-PQDTLM61T3"
};

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;

export const getToken = (setTokenFound) => {
  if (messaging) {
    return messaging.getToken({ vapidKey: 'BOLLNlO7LDBAPWdIQ_DtD2fALzDvQeqg1zdFZvt895NnqaxmP7Adrs8oiXEAKkxM6-p1ZB-y-ADJiMQeBk3PYEo' })
      .then((currentToken) => {
        if (currentToken) {
          console.log("Token: " + currentToken);
          setTokenFound(true);
          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
        } else {
          setTokenFound(false);
          // shows on the UI that permission is required 
        }
      }).catch((err) => {
        setTokenFound(false);
        // catch error while creating client token
      })
  } else {
    setTokenFound(false);
  }
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  }
  );