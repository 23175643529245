import React from 'react';
import { faCog, faQrcode, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import logo from './logo.svg';
import { Register } from './web-authn/Register';
import { CodeScanner } from './qr-scanner/CodeScanner'
import { Home } from './Home';
import { Settings } from './app-settings/Settings';

export const Content = () => {
    return (
        <Router>
            <div className="navbar fixed-top navbar-dark bg-dark">
                <ul className="nav text-center">
                    <li className="nav-item">
                        <Link className="navbar-brand" to="/">
                            <img src={logo} height={"35"} alt="logo" />
                        </Link>
                    </li>
                    <LinkItem icon={faUser} text={"Webauthn"} target={"/register"} />
                    <LinkItem icon={faQrcode} text={"QR scanner"} target={"/scanner"} />
                    <LinkItem icon={faCog} text={"Settings"} target={"/settings"} />
                </ul>
            </div>
            <div className="container-sm">
                <Switch>
                    <Route path="/register"><Register /></Route>
                    <Route path="/scanner"><CodeScanner /></Route>
                    <Route path="/settings"><Settings /></Route>
                    <Route path="/"><Home /></Route>
                </Switch>
            </div>
        </Router>
    );
}

const LinkItem = ({ icon, text, target }) => {
    const HEIGHT = "60";
    return (
        <li className="nav-item">
            <Link className="nav-link text-white" to={target}>
                <FontAwesomeIcon icon={icon} color="white" height={HEIGHT} />
                <br /><small color="white">{text}</small>
            </Link>
        </li>
    );
}