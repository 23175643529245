import React, { useState, useEffect } from 'react';
import { BrowserQRCodeReader } from '@zxing/library';

export const CodeScanner = () => {
    const [codeReader] = useState(new BrowserQRCodeReader());
    const [devices, setDevices] = useState([]);
    const [selectedDeviceId, setSelectedDeviceId] = useState(undefined);
    const [result, setResult] = useState("");
    const [errorMsg, setErrorMsg] = useState("");

    async function getDevices() {
        var devices = [];
        try {
            const d = await codeReader.getVideoInputDevices();
            if (d) {
                d.forEach(device => {
                    devices.push(device);
                })
                setDevices(devices);
            }
        } catch (e) {
            setResult(e.message);
        } finally {
        }
    }

    useEffect(() => {
        getDevices();
        if (!setSelectedDeviceId) {
            setSelectedDeviceId(devices[0].deviceId);
        }
    }, []);

    function startScanner() {
        setErrorMsg("");
        decodeOnce(codeReader, selectedDeviceId);
    }

    function resetResetScanner() {
        codeReader.reset();
        setResult("");
        setErrorMsg("");
    }

    function selectDevice(e) {
        setSelectedDeviceId(e.target.value);
    }

    function decodeOnce(codeReader, selectedDeviceId) {
        codeReader.decodeFromInputVideoDevice(selectedDeviceId, 'video').then((result) => {
            setResult(result.text);
            codeReader.reset();
        }).catch((err) => {
            setErrorMsg(err.message);
        })
    }

    return (
        <div>
            <h3>QR-Code Scanner</h3>
            <div id="sourceSelectPanel" className="form-group">
                <label htmlFor="sourceSelect">Video device</label>
                <select id="sourceSelect" className="form-control" onChange={selectDevice}>
                    {devices.map((device, index) => (
                        <option
                            key={index}
                            value={device.deviceId}
                        >
                            {device.label}
                        </option>
                    ))}
                </select>
            </div>

            <div className="form-group">
                <button id="startButton" className="btn btn-dark mr-2" onClick={startScanner}>Start</button>
                <button id="resetButton" className="btn btn-dark mr-2" onClick={resetResetScanner}>Reset</button>
            </div>

            <div className="form-group">
                {result.length > 0 &&
                    <div className="alert alert-primary" role="alert">
                        <b>Result: </b> {result}
                    </div>
                }
                {errorMsg.length > 0 &&
                    <div className="alert alert-danger" role="alert">
                        {errorMsg}
                    </div>
                }
            </div>

            <div className="form-group">
                <div className="embed-responsive embed-responsive-1by1">
                    <video id="video" className="embed-responsive-item"></video>
                </div>
            </div>

        </div>
    );
}