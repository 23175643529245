import React from 'react';

export const Home = () => {

    return (
        <>
            <h3>About</h3>
            <p>This App is just a POC to get familiar with developing a PWA (Progressive Web App)
                using React.
            </p>
            Used technologies and references:
            <ul>
                    <li>React using 'cra-template-pwa'</li>
                    <li>Bootstrap for styling</li>
                    <li>
                        <a href="https://zxing-js.github.io/library/" rel="noreferrer" target="_blank">ZXING</a> for QR-code-scanner
                    </li>
                    <li>
                        The WebAuthn example is based on
                        <a href="https://github.com/dvas0004/web-auth" rel="noreferrer" target="_blank"> this project</a>.
                    </li>
                    <li>
                        <a href="https://www.npmjs.com/package/react-cookie-consent" rel="noreferrer" target="_blank">Cookie consent library</a>
                    </li>
                    <li>
                        Firebase for push-notifications
                    </li>
                </ul>
        </>

    );
}