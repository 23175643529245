import React, { useState, useEffect } from 'react';
import { createCreds, validateCreds } from './Authn';

export const Register = () => {
    const [emailAddress, setEmailAddress] = useState("");
    const [authResponse, setAuthRsponse] = useState("");
    const [error, setError] = useState("");

    useEffect(() => {

    }, [authResponse, error]);

    function register() {
        createCreds(emailAddress).then(function (value) {
            setAuthRsponse(value);
            setError("");
        }).catch(function (e) {
            setError(e.message);
            setAuthRsponse("")
        })
    }

    function validate() {
        validateCreds().then(function (value) {
            setAuthRsponse(value);
            setError("");
        }).catch(function (e) {
            setError(e.message);
            setAuthRsponse("")
        });
    }

    return (
        <div>
            <h3>Webauthn (TPM)</h3>
            <div className="form-group">
                <input type="text"
                    className="form-control"
                    placeholder="Username"
                    onChange={e => { setEmailAddress((e.target.value)) }} />
            </div>
            <div className="form-group">
                <button className="btn btn-dark mr-2" onClick={register}>Register</button>
                <button className="btn btn-dark mr-2" onClick={validate}>Authenticate</button>
            </div>
            <p>
                {authResponse.length > 0 &&
                    <div className="alert alert-success" role="alert">{authResponse}</div>}
                {error.length > 0 &&
                    <div className="alert alert-danger" role="alert">{error}</div>}
            </p>
        </div>
    );
}