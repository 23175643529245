import React, { useState } from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './custom.css';
import CookieConsent from "react-cookie-consent";
import { Content } from './Content';
import { getToken } from './notification/firebase';
//serve -s build
export const AppContext = React.createContext();

function App() {
  const notificationSupported = ('Notification' in window);
  const [isTokenFound, setTokenFound] = useState(undefined);

  if (notificationSupported) {
    getToken(setTokenFound);
  }

  return (
    <>
      <AppContext.Provider value={isTokenFound}>
        <Content />
        <CookieConsent
          location={"bottom"}
          buttonText="Accept"
          cookieName="CookieConsent"
          cookieValue="accepted"
          expires={1}
          disableStyles={false}
          style={{ background: "#d6d8d9", color: "black" }}
          buttonStyle={{ color: "#fff", background: "#343a40", borderRadius: ".3rem" }}
        >
          This website uses cookies to enhance the user experience.{" "}
        </CookieConsent>
      </AppContext.Provider>
    </>
  );
}

export default App;
