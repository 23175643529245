import React from 'react';
import { AppContext } from '../App';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

export const Settings = () => {
    const platform = getPlatform();
    const appVersion = navigator.userAgent.split(')').reverse()[0].match(/(?!Gecko|Version|[A-Za-z]+?Web[Kk]it)[A-Z][a-z]+\/[\d.]+/g)[0].split('/');
    const webAuthnSupport = isWebAuthnSupported();

    function getPlatform() {
        var os = "Unknown OS";
        if (navigator.userAgent.indexOf("Win") !== -1) os = "Windows";
        if (navigator.userAgent.indexOf("Mac") !== -1) os = "Macintosh";
        if (navigator.userAgent.indexOf("Linux") !== -1) os = "Linux";
        if (navigator.userAgent.indexOf("Android") !== -1) os = "Android";
        if (navigator.userAgent.indexOf("like Mac") !== -1) os = "iOS";
        return os;
    }

    function isWebAuthnSupported() {
        return (typeof (PublicKeyCredential) == "undefined") ? false : true;
    }

    return (
        <div>
            <h3>Settings</h3>
            <div className="form-group row">
                <label htmlFor="platform" className="col-sm-4 col-form-label"><strong>Operating system:</strong></label>
                <div className="col-sm-8">
                    <input type="text" readOnly={true} className="form-control-plaintext" id="platform" value={platform} />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="appVersion" className="col-sm-4 col-form-label"><strong>Browser version:</strong></label>
                <div className="col-sm-8">
                    <textarea readOnly={true} className="form-control-plaintext" id="appVersion" value={appVersion} />
                </div>
            </div>
            <div className="form-group row">
                <div className="col-sm-4"><strong>Notifications enabled:</strong></div>
                <div className="col-sm-8">
                    <div className="form-check">
                        <AppContext.Consumer>
                            {value =>
                                <BootstrapSwitchButton
                                    checked={value}
                                    onstyle="dark"
                                    size="xs"
                                    disabled
                                />
                            }
                        </AppContext.Consumer>
                    </div>
                </div>
            </div>
            <div className="form-group row">
                <div className="col-sm-4"><strong>Webauthn supported:</strong></div>
                <div className="col-sm-8">
                    <div className="form-check">
                        <BootstrapSwitchButton
                            checked={webAuthnSupport}
                            onlabel={"Yes"}
                            offlabel={"No"}
                            onstyle="dark"
                            size="xs"
                            disabled
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}